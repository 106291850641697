import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getReceiptInfos } from "../services/Receipt";
import type { ReceiptDataResponse } from "../types/index.types";
import useQuery from "./useQuery";

export const useReceiptData = () => {
	const { transactionId } = useParams<{ transactionId: string }>();
	const query = useQuery();
	const boxName = query.get("boxName");
	const [receiptData, setReceiptData] = useState<ReceiptDataResponse | null>(
		null,
	);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (!boxName || !transactionId) return;
		const fetchReceiptData = async () => {
			try {
				setIsLoading(true);
				const data = await getReceiptInfos(transactionId, boxName);
				setReceiptData(data);
			} catch (error) {
				setReceiptData(null);
			} finally {
				setIsLoading(false);
			}
		};
		fetchReceiptData();
	}, [transactionId, boxName]);

	return { receiptData, isLoading, transactionId, boxName };
};
