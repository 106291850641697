import type {
	PdfResponse,
	ReceiptDataResponse,
	SaveEmailResponse,
} from "../types/index.types";

const apiUrl = process.env.REACT_APP_FILLNDRIVE_API_URL;

const fetchData = async <T extends { error?: boolean }>(
	url: string,
	method = "GET",
	headers?: HeadersInit,
	body?: BodyInit | null,
): Promise<T | null> => {
	try {
		const options: RequestInit = {
			method,
			headers: {
				"Content-Type": "application/json",
				...headers,
			},
			body: body ? JSON.stringify(body) : null,
		};
		const res = await fetch(url, options);
		const data: T = await res.json();
		if (!res.ok) {
			throw new Error(data.error ? JSON.stringify(data) : res.statusText);
		}
		return data;
	} catch (e) {
		return null;
	}
};

export const getReceiptInfos = async (
	transactionId: string,
	boxName: string,
): Promise<ReceiptDataResponse | null> => {
	const receiptUrl = `${apiUrl}/receipt/transaction/${transactionId}?boxName=${boxName}`;
	return await fetchData<ReceiptDataResponse>(receiptUrl);
};

export const saveEmail = async (
	transactionId: string,
	boxName: string | null,
	email: string,
): Promise<boolean> => {
	const emailUrl = `${apiUrl}/receipt/delayed/${transactionId}?boxName=${boxName}&email=${email}`;
	const data = await fetchData<SaveEmailResponse>(emailUrl, "POST");
	return data !== null && !data.error;
};

export const getReceiptPdf = async (
	transactionId: string,
	boxName: string,
): Promise<{ pdfUrl: string } | null> => {
	const pdfUrl = `${apiUrl}/receipt/pdf/${transactionId}?boxName=${boxName}`;
	return await fetchData<PdfResponse>(pdfUrl);
};
